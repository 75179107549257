
















































































import { Component, Vue } from 'vue-property-decorator'
import i18n from '@/i18n'
import { loginStore } from '@/store'
import { logout } from '@/utils'
import { routerLogger } from '@/loggers'

@Component({
  name: 'TheOnboardingView',
  components: {
    IconExit: () =>
      import('@/components/UI/icons/LoginBadIcons/IconExitNew.vue'),
  },
})
export default class TheOnboardingView extends Vue {
  /**
   * CUrrent language
   * probably 'en' | 'ru'
   */
  language = 'ru'

  created () {
    this.language = i18n.locale ?? 'ru'
  }

  /**
   * Get custom background picture from backend on right side of scree
   */
  get bgStyle () {
    return loginStore.getters.backgroundStyles
  }

  /**
   * Get faq pages based on locale
   */
  get faqHref () {
    const base = 'https://tada.team/faq-'
    return base + i18n.locale
  }

  /**
   * Reverted label for langs means enable ANOTHER lang by clicking :)
   * IF current EN -> show Русский
   * ELSE (===RU) -> English
   */
  get changeLanguageLabel () {
    return this.language === 'ru' ? 'English' : 'Русский'
  }

  /**
   * Change language by loginStore action
   */
  async changeLanguage () {
    this.language = await loginStore.actions.changeLang(false)
  }

  /**
   * Logaut from application
   * @see {logaut}
   * @todo remove logout using, cause it's page reloading
   */
  logout () {
    window.goal('onboardingAction', { onboarding: `${this.getLocation()} — логаут` })
    logout()
    this.$router.push({ name: 'Login' }).catch(e => {
      routerLogger.debug('Router warning Login: ', e)
    })
  }

  /**
   * Send metric for actions FAQ click
   */
  onFaqClick () {
    window.goal('onboardingAction', { login: `${this.getLocation()} — «Вопрос-ответ»` })
  }

  /**
   * Send metric for actions support click
   */
  onSupportClick () {
    window.goal('onboardingAction', { onboarding: `${this.getLocation()} — «Поддержка»` })
  }

  /**
   * Get the name of the current location on the on boarding page
   * @private
   */
  private getLocation (): string {
    const routePath = this.$router.currentRoute.path
    const locations: Record<string, string> = {
      '/onboarding/team': 'Создать команду',
      '/onboarding/invitations': 'Приглашения',
      '/onboarding/invites': 'Пригласите коллег',
      '/onboarding/profile': 'Расскажите о себе',
      '/onboarding/view': 'Управление командами',
    }
    return locations[routePath] ?? ''
  }
}
